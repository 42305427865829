import React from 'react';
import icon1 from '../../assets/images/icon/pages/image25.svg';
import icon2 from '../../assets/images/icon/pages/image26.svg';
import icon3 from '../../assets/images/icon/pages/image27.svg';
import icon4 from '../../assets/images/icon/pages/image28.svg';

function ServicesHomeOne3({ className }) {
    return (
        <section
            className={`appie-service-area appie-service-area-app-models2 pt-90 pb-100 ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="text-center">
                            <h2 className="text-aftim-app">Con AFTIM</h2>
                            <p className="mt-5 mb-3 font-weight-bold text-wrap">
                                AFTIM puede adaptarse a todas tus necesidades ya sea pequeño o
                                grande tu negocio
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img
                                className="img-fluid w-25"
                                src={icon1}
                                alt="Icono Necocios Pequeños"
                            />
                            <h6 className="my-3 text-aftim-app">Necocios Pequeños</h6>
                        </div>
                    </div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img
                                className="img-fluid w-25"
                                src={icon2}
                                alt="Icono Necocios Grandes"
                            />
                            <h6 className="my-3 text-aftim-app">Necocios Grandes</h6>
                        </div>
                    </div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img className="img-fluid w-25" src={icon3} alt="Icono Franquicias" />
                            <h6 className="my-3 text-aftim-app">Franquicias</h6>
                        </div>
                    </div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img
                                className="img-fluid w-25"
                                src={icon4}
                                alt="Icono Negocios en Cadena"
                            />
                            <h6 className="my-3 text-aftim-app">Negocios en Cadena</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne3;

import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
/* import BlogHomeOne from './BlogHomeOne'; */
/* import FaqHomeOne from './FaqHomeOne'; */
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from '../HomeOne/HomeOneHeader';
/* import PricingHomeOne from './PricingHomeOne'; */
/* import ProjectHomeOne from './ProjectHomeOne'; */
import ServicesHomeOne from './ServicesHomeOne';
import ServicesHomeOne2 from './ServicesHomeOne2';
/* import TeamHomeOne from './TeamHomeOne'; */
import TestimonialHomeOne from './TestimonialHomeOne';
import TrafficHomeOne from './TrafficHomeOne';
import ShowCaseHomeThree from './ShowCaseHomeThree';

function HomeRetail() {
    const [drawer, drawerAction] = useToggle(false);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroHomeOne />
            <ServicesHomeOne2 />
            <ServicesHomeOne />
            <TestimonialHomeOne />
            <TrafficHomeOne />
            <ShowCaseHomeThree />
            {/* <TeamHomeOne /> */}
            {/* <PricingHomeOne /> */}
            {/* <FaqHomeOne /> */}
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeRetail;

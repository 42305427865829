import React, { useRef } from 'react';
/* import { Link } from 'react-router-dom'; */
import Slider from 'react-slick';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../../assets/css/style-2.css';
import shape12 from '../../assets/images/shape/shape-12.png';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';
import showCaseOne from '../../assets/images/models/AFTIM-img-1.jpg';
import showCaseTwo from '../../assets/images/models/AFTIM-img-2.jpg';
import showCaseThree from '../../assets/images/models/AFTIM-img-3.jpg';
import showCaseFour from '../../assets/images/models/AFTIM-img-4.jpg';
import showCaseFive from '../../assets/images/models/AFTIM-img-5.jpg';

function ShowCaseHomeThree() {
    const sliderRef = useRef();
    // const sliderNext = () => {
    //     sliderRef.current.slickNext();
    // };
    // const sliderPrev = () => {
    //     sliderRef.current.slickPrev();
    // };
    const settings = {
        autoplay: false,
        arrows: false,
        dots: true,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className="appie-showcase-area" id="demos">
                <SimpleReactLightbox>
                    <SRLWrapper>
                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="appie-section-title text-center">
                                        <h3 className="appie-title">Prueba nuestros demos</h3>
                                        <p>Todos al alcance de tu mano.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row appie-showcase-slider">
                                <div className="col-lg-12">
                                    <Slider ref={sliderRef} {...settings}>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img className="" src={showCaseOne} alt="" />
                                                    <a
                                                        className="main-btn btn-demo ml-5"
                                                        href="https://dist.aftim.app"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Ver demo
                                                    </a>
                                                    {/* <Link
                                                        className="main-btn btn-demo ml-5"
                                                        to="https://dist.aftim.app"
                                                    >
                                                        Ver demo
                                                    </Link> */}
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img className="" src={showCaseTwo} alt="" />
                                                    <a
                                                        className="main-btn btn-demo ml-5"
                                                        href="https://dist.aftim.app"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Ver demo
                                                    </a>
                                                    {/* <Link className="main-btn btn-demo ml-5" to="">
                                                        Ver demo
                                                    </Link> */}
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img className="" src={showCaseThree} alt="" />
                                                    <a
                                                        className="main-btn btn-demo ml-5"
                                                        href="https://market.aftim.app"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Ver demo
                                                    </a>
                                                    {/* <Link className="main-btn btn-demo ml-5" to="">
                                                        Ver demo
                                                    </Link> */}
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img className="" src={showCaseFour} alt="" />
                                                    <a
                                                        className="main-btn btn-demo ml-5"
                                                        href="https://farmacia.aftim.app"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Ver demo
                                                    </a>
                                                    {/* <Link className="main-btn btn-demo ml-5" to="">
                                                        Ver demo
                                                    </Link> */}
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appie-showcase-item mt-30">
                                                <a className="appie-image-popup">
                                                    <img className="" src={showCaseFive} alt="" />
                                                    <a
                                                        className="main-btn btn-demo ml-5"
                                                        href="https://ayb.aftim.app"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Ver demo
                                                    </a>
                                                    {/* <Link className="main-btn btn-demo ml-5" to="">
                                                        Ver demo
                                                    </Link> */}
                                                </a>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                        <div className="showcase-shape-1">
                            <img src={shape14} alt="" />
                        </div>
                        <div className="showcase-shape-2">
                            <img src={shape13} alt="" />
                        </div>
                        <div className="showcase-shape-3">
                            <img src={shape12} alt="" />
                        </div>
                        <div className="showcase-shape-4">
                            <img src={shape15} alt="" />
                        </div>
                    </SRLWrapper>
                </SimpleReactLightbox>
            </section>
        </>
    );
}

export default ShowCaseHomeThree;

import React from 'react';
import IconOne from '../../assets/images/icon/2.ICONO-SINCRONIZACION.png';
import IconTwo from '../../assets/images/icon/2.ICONO-GESTION.png';
import IconThree from '../../assets/images/icon/2.ICONO-SEGURIDAD.png';
import IconFour from '../../assets/images/icon/2.ICONO-SOPORTE.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title text-aftim-app">
                                Con AFTIM, haz crecer tu NEGOCIO
                            </h3>
                            <p className="text-aftim-app">
                                Gestiona todas tus sucursales desde un solo lugar
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon bg-aftim-app">
                                <img className="w-50" src={IconOne} alt="" />
                            </div>
                            <h4 className="appie-title">Sincronización</h4>
                            <p>Información disponible donde sea y cuando sea.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon bg-aftim-app">
                                <img className="w-50" src={IconTwo} alt="" />
                            </div>
                            <h4 className="appie-title">Gestión</h4>
                            <p>El control de tu empresa en cualquier dispositivo.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon bg-aftim-app">
                                <img className="w-50" src={IconThree} alt="" />
                            </div>
                            <h4 className="appie-title">Seguridad</h4>
                            <p>Respaldado y asegurado ante interrupciones.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon bg-aftim-app">
                                <img className="w-50" src={IconFour} alt="" />
                            </div>
                            <h4 className="appie-title">Soporte</h4>
                            <p>Atención personalizada en todo momento.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;

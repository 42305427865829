import React from 'react';
import thumb from '../../assets/images/4.png';

function TrafficHomeOne() {
    return (
        <section className="appie-traffic-area pt-140 pb-180">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="appie-traffic-title">
                            <span>Inteligencia de Negocios (BI)</span>
                            <h3 className="title">Monitorea tu empresa con inteligencia.</h3>
                            <p>
                                Convierta los datos en oportunidades con las herramientas de
                                visualización de datos de AFTIM BI. Impulse mejores decisiones
                                empresariales analizando los datos de su empresa para obtener bases
                                de conocimiento.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service pr-0">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">
                                        Obtenga análisis en autoservicio a escala empresarial
                                    </h5>
                                    <p>
                                        Reduzca el costo adicional, la complejidad y los riesgos de
                                        seguridad de múltiples soluciones con plataformas de
                                        análisis que se extienden desde individuos hasta
                                        organizaciones enteras.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-2 pr-0">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">
                                        Utilizar herramientas inteligentes para obtener resultados
                                        sólidos
                                    </h5>
                                    <p>
                                        Encuentre y comparta información útil a través de una
                                        integración perfecta con gráficos interactivos, cientos de
                                        visualizaciones de datos, capacidades nativas de IA y
                                        conectores de datos prediseñados y personalizados.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-3 pr-0">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">
                                        Ayude a proteger sus datos de análisis
                                    </h5>
                                    <p>
                                        Proteja sus datos con características de seguridad de datos
                                        líderes en la industria, como etiquetado de
                                        confidencialidad, cifrado de extremo a extremo y control de
                                        acceso en tiempo real.
                                    </p>
                                </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-4">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">User Interactive</h5>
                                    <p>Mucker plastered bugger all mate morish are.</p>
                                </div>
                            </div> */}
                            <div className="col-lg-12">
                                <div className="traffic-btn mt-50">
                                    <a className="main-btn" href="#">
                                        Descúbrelo <i className="fal fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="traffic-thumb">
                <img
                    className="wow animated fadeInRight w-100"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                    src={thumb}
                    alt=""
                />
            </div>
        </section>
    );
}

export default TrafficHomeOne;

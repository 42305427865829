import React from 'react';
import iconCaracteristicas from '../../assets/images/icon/pages/clasificacion.svg';
import iconSales from '../../assets/images/icon/pages/oferta-especial.svg';
import iconFolder from '../../assets/images/icon/pages/carpeta.svg';
import iconSatatus from '../../assets/images/icon/pages/Group.svg';
import iconCredictCard from '../../assets/images/icon/pages/tarjetas-de-credito.svg';
import iconCash from '../../assets/images/icon/pages/dinero-en-efectivo.svg';

function ServicesHomeOne2({ className }) {
    return (
        <section
            className={`appie-service-area appie-service-area-app-models pt-90 pb-100 ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="text-center">
                            <h2 className="text-aftim-app">Caracteristicas del software</h2>
                        </div>
                    </div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img
                                className="img-fluid w-25"
                                src={iconCaracteristicas}
                                alt="Icono Gestión de Clientes"
                            />
                            <h6 className="my-3 text-aftim-app">Gestión de clientes</h6>
                            <p>
                                Gracias a AFTIM tendrás: albaranes, facturas, histórico de ventas,
                                tarifas, promociones, pedidos… Fideliza a tu cliente.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img
                                className="img-fluid w-25"
                                src={iconSales}
                                alt="Icono Ofertas y promociones"
                            />
                            <h6 className="my-3 text-aftim-app">Ofertas y promociones</h6>
                            <p>
                                Podrás gestionar fácilmente las ofertas y promociones de tu negocio
                                como quieras y de los productos que quieras, Programa tus ofertas de
                                manera automática gracias a AFTIM.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img
                                className="img-fluid w-25"
                                src={iconFolder}
                                alt="Icono Gestión de catálogo"
                            />
                            <h6 className="my-3 text-aftim-app">Gestión de catálogo</h6>
                            <p>
                                En tu casa, mandas tú. Gestiona las familias, los grupos mayores,
                                las categorías, el stock, los precios de venta, el coste, los
                                márgenes y mucho más.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img
                                className="img-fluid w-25"
                                src={iconSatatus}
                                alt="Icono Apertura y cierre de caja"
                            />
                            <h6 className="my-3 text-aftim-app">Apertura y cierre de caja</h6>
                            <p>
                                El cierre de caja siempre es una odisea sea cual sea tu negocio.
                                Ahora ya no, lo tendrás todo bajo control gracias a lo último en
                                tecnología con AFTIM Software.
                            </p>
                        </div>
                    </div>
                    <div className="col md-3"></div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img
                                className="img-fluid w-25"
                                src={iconCredictCard}
                                alt="Icono Tarjetas bancarias"
                            />
                            <h6 className="my-3 text-aftim-app">Tarjetas bancarias</h6>
                            <p>
                                Integramos los datáfonos de cobro de tarjetas a través de una
                                pasarela de pagos, ahorrando tiempos en los cobros, evitando errores
                                en las transacciones y emitirás un único recibo con la factura y
                                transacción bancaria, además de minimizar los tiempos en el cuadre
                                de caja.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 my-5">
                        <div className="text-center">
                            <img
                                className="img-fluid w-25"
                                src={iconCash}
                                alt="Icono Gestión de efectivo"
                            />
                            <h6 className="my-3 text-aftim-app">Gestión de efectivo</h6>
                            <p>
                                El control del efectivo es muy importante. Podrás tener la seguridad
                                de que la caja siempre cuadre, evitando la pérdida desconocida del
                                efectivo y ahorrarás tiempos en los cuadres de caja al final del
                                día.
                            </p>
                        </div>
                    </div>
                    <div className="col md-3"></div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne2;

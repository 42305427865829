import React from 'react';
import Elipse1 from '../../assets/images/icon/shapes/Ellipse13.png';
import Elipse2 from '../../assets/images/icon/shapes/Ellipse14.png';
import Elipse3 from '../../assets/images/icon/shapes/Ellipse15.png';
import Elipse5 from '../../assets/images/icon/shapes/Ellipse17.png';
import Elipse6 from '../../assets/images/icon/shapes/Ellipse18.png';
import Elipse7 from '../../assets/images/icon/shapes/Ellipse19.png';

function TrafficHomeOne() {
    return (
        <section className="appie-traffic-area pt-80 pb-40">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="text-center pb-5 mb-5">
                            <h2 className="title">
                                Herramientas de <span className="title text-aftim-app">AFTIM</span>{' '}
                                para tu <span className="title text-aftim-app">NEGOCIO</span>
                            </h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row border-bottom border-primary py-3">
                            <div className="col-md-10">
                                <span className="badge bg-primary text-light py-3 px-3 mb-3">
                                    SISTEMA CENTRALIZADO
                                </span>
                                <p>
                                    Si tienes más de un negocio, una franquicia o grupos de locales
                                    con AFTIM podrás gestionar todo en una única base de datos.
                                    Siempre utilizarás la misma herramienta en un entorno totalmente
                                    web, ahorrando tiempos y con toda la información de ventas en
                                    tiempo real de todos tus establecimientos. Nunca fue tan fácil
                                    expandir tus negocios en nuevas aperturas con nuestra
                                    herramienta de centralización.
                                </p>
                            </div>
                            <div className="col-md-2">
                                <div className="text-center">
                                    <img className="img-fluid w-75" src={Elipse1} alt="Elipse1" />
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom border-primary py-3">
                            <div className="col-md-2">
                                <div className="text-center">
                                    <img className="img-fluid w-75" src={Elipse2} alt="Elipse1" />
                                </div>
                            </div>
                            <div className="col-md-10">
                                <span className="badge bg-primary text-light py-3 px-3 mb-3">
                                    E-COMMERCE
                                </span>
                                <p>
                                    Gracias a su integración con el CMS Prestashop, AFTIM te
                                    permitirá gestionar tu e-commerce de manera sencilla.
                                </p>
                            </div>
                        </div>
                        <div className="row border-bottom border-primary py-3">
                            <div className="col-md-10">
                                <span className="badge bg-primary text-light py-3 px-3 mb-3">
                                    ETIQUETADO
                                </span>
                                <p>
                                    Con AFTIM es sencillo y rápido etiquetar los productos nuevos
                                    que entran en tu establecimiento.
                                </p>
                            </div>
                            <div className="col-md-2">
                                <div className="text-center">
                                    <img className="img-fluid w-75" src={Elipse3} alt="Elipse1" />
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom border-primary py-3">
                            <div className="col-md-2">
                                <div className="text-center">
                                    <img className="img-fluid w-75" src={Elipse7} alt="Elipse1" />
                                </div>
                            </div>
                            <div className="col-md-10">
                                <span className="badge bg-primary text-light py-3 px-3 mb-3">
                                    AUDITORÍA
                                </span>
                                <p>
                                    Bastante tienes con gestionar tu negocio. Cada movimiento que se
                                    realice queda registrado, podrás hacer consultas entre fechas,
                                    por empleados, filtrar las cancelaciones de líneas, de tickets,
                                    las devoluciones, los descuentos, las aperturas de caja… Con
                                    esta herramienta tendrás el control total.
                                </p>
                            </div>
                        </div>
                        <div className="row border-bottom border-primary py-3">
                            <div className="col-md-10">
                                <span className="badge bg-primary text-light py-3 px-3 mb-3">
                                    INTEGRACIONES
                                </span>
                                <p>
                                    AFTIM no vive solo, estamos abiertos a colaborar con otras
                                    aplicaciones de mercado (ERP´s, contabilidades, sistemas de
                                    fidelización, sistemas de reservas…)
                                </p>
                            </div>
                            <div className="col-md-2">
                                <div className="text-center">
                                    <img className="img-fluid w-75" src={Elipse5} alt="Elipse1" />
                                </div>
                            </div>
                        </div>
                        <div className="row border-bottom border-primary py-3">
                            <div className="col-md-2">
                                <div className="text-center">
                                    <img className="img-fluid w-75" src={Elipse6} alt="Elipse1" />
                                </div>
                            </div>
                            <div className="col-md-10">
                                <span className="badge bg-primary text-light py-3 px-3 mb-3">
                                    APP ALMACÉN
                                </span>
                                <p>
                                    Gestiona la entrada de mercancías, los inventarios y las
                                    regulaciones de stock. Y todo, desde tu móvil de manra facil y
                                    rapida.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TrafficHomeOne;

import React from 'react';
import img1 from '../../assets/images/clientes/zabora.jpg';
import img2 from '../../assets/images/clientes/zona-grill.jpg';
import img3 from '../../assets/images/clientes/pastelito-maracucho.jpg';
import img4 from '../../assets/images/clientes/d-la-costa.jpg';

function TestimonialHomeRestaurant() {
    return (
        <section className="appie-testimonial-area pt-100 pb-5" id="testimonial">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="text-light">Clientes AFTIM Restaurant</h3>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="text-center">
                            <img
                                className="img-fluid w-50 img-thumbnail rounded-circle"
                                src={img1}
                                alt="Cliente 1"
                            />
                            <h6 className="text-light my-3">
                                Zabora Restaurant <br /> Caracas
                            </h6>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="text-center">
                            <img
                                className="img-fluid w-50 img-thumbnail rounded-circle"
                                src={img2}
                                alt="Cliente 2"
                            />
                            <h6 className="text-light my-3">Zona Grill</h6>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="text-center">
                            <img
                                className="img-fluid w-50 img-thumbnail rounded-circle"
                                src={img3}
                                alt="Cliente 3"
                            />
                            <h6 className="text-light my-3">
                                El propio pastelito maracuco <br /> Isla de Margarita
                            </h6>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="text-center">
                            <img
                                className="img-fluid w-50 img-thumbnail rounded-circle"
                                src={img4}
                                alt="Cliente 4"
                            />
                            <h6 className="text-light my-3">
                                D' La Costa <br /> Cumana
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeRestaurant;

import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImage1 from '../../assets/images/emp/beautiful-family-standing-at-the-cash-counter2.png';
import backgroundImage2 from '../../assets/images/emp/plate-of-fried-fish2.png';
import backgroundImage3 from '../../assets/images/emp/top-view-delicious-fruit-salad-inside-plate-with-fresh-fruits-on-gray-tropical-fruit-tree-exotic-ripe-diet-photo 2.png';
import backgroundImage4 from '../../assets/images/emp/farmacia-copia.jpg';

function ServicesHomeOne2({ className }) {
    return (
        <section
            className={`appie-service-area appie-service-area-app pt-5 pb-100 ${className}`}
            id="service"
        >
            <div className="container">
                <div className="row mt-n35">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                                appie-team-item appie-team-item-8
                                mt-30
                                wow
                                animated
                                fadeInUp
                            "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="thumb rounded">
                                <img className="img-fluid rounded" src={backgroundImage1} alt="" />
                                <ul>
                                    <li className="text-center">
                                        <h4 className="text-light">RETAIL</h4>
                                        <Link
                                            className="main-btn bg-aftim-app mt-3"
                                            to="/home-retail"
                                        >
                                            Descúbrelo
                                        </Link>
                                        {/* <button
                                            type="button"
                                            className="main-btn bg-aftim-app mt-3"
                                        >
                                            Descúbrelo
                                        </button> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                                appie-team-item appie-team-item-8
                                mt-30
                                wow
                                animated
                                fadeInUp
                            "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="thumb rounded">
                                <img className="img-fluid rounded" src={backgroundImage2} alt="" />
                                <ul>
                                    <li className="text-center">
                                        <h4 className="text-light">RESTAURANT</h4>
                                        <Link
                                            className="main-btn bg-aftim-app mt-3"
                                            to="/home-restaurant"
                                        >
                                            Descúbrelo
                                        </Link>
                                        {/* <button
                                            type="button"
                                            className="main-btn bg-aftim-app mt-3"
                                        >
                                            Descúbrelo
                                        </button> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                                appie-team-item appie-team-item-8
                                mt-30
                                wow
                                animated
                                fadeInUp
                            "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="thumb rounded">
                                <img
                                    className="img-fluid rounded"
                                    src={backgroundImage3}
                                    alt="img-market"
                                />
                                <ul>
                                    <li className="text-center">
                                        <h4 className="text-light">MARKET</h4>
                                        <Link
                                            className="main-btn bg-aftim-app mt-3"
                                            to="/home-market"
                                        >
                                            Descúbrelo
                                        </Link>
                                        {/* <button
                                            type="button"
                                            className="main-btn bg-aftim-app mt-3"
                                        >
                                            Descúbrelo
                                        </button> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="
                                appie-team-item appie-team-item-8
                                mt-30
                                wow
                                animated
                                fadeInUp
                            "
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="thumb rounded">
                                <img
                                    className="img-fluid rounded"
                                    src={backgroundImage4}
                                    alt="img-farmacia"
                                />
                                <ul>
                                    <li className="text-center">
                                        <h4 className="text-light">FARMACIA</h4>
                                        <Link
                                            className="main-btn bg-aftim-app mt-3"
                                            to="/home-farmacia"
                                        >
                                            Farmacia
                                        </Link>
                                        {/* <button
                                            type="button"
                                            className="main-btn bg-aftim-app mt-3"
                                        >
                                            Descúbrelo
                                        </button> */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne2;

import React from 'react';
import IconStock from '../../assets/images/icon/pages/inventario.svg';
import IconComputer from '../../assets/images/icon/pages/computadora.svg';
import IconDevices from '../../assets/images/icon/pages/dispositivos.svg';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title text-aftim-app">
                                ¿Como puede ayudarte AFTIM?
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <img
                                className="img-fluid w-25"
                                src={IconStock}
                                alt="Icono Control de stock"
                            />
                            <h6 className="my-3 text-aftim-app">Control de stock</h6>
                            <p>
                                Gestiona los pedidos a tus proveedores, la entrada de mercancía, las
                                facturas, los inventarios, las mermas y obtendrás los resultados de
                                rentabilidad al momento.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <img
                                className="img-fluid w-25"
                                src={IconComputer}
                                alt="Icono Análisis económico"
                            />
                            <h6 className="my-3 text-aftim-app">Análisis económico</h6>
                            <p>
                                ¿Qué estás haciendo bien? ¿Qué puedes mejorar? NO, no hace falta un
                                experto financiero porque el experto ahora lo serás tú gracias a
                                todos los informes que te proporciona AFTIM.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <img
                                className="img-fluid w-25"
                                src={IconDevices}
                                alt="Icono Acceso multiplataforma"
                            />
                            <h6 className="my-3 text-aftim-app">Acceso multiplataforma</h6>
                            <p>
                                Podrás gestionar tu/s comerio/s porque puedes conectarte desde
                                cualquier lugar y con cualquier dispositivo, ya sea iOS, Android o
                                Windows, nuestra gestión es totalmente web. No tendrás que estar
                                físicamente el POS, ni tener que conectarte remotamente al mismo POS
                                que están utilizando los empleados para poder gestionar.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;

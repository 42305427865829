import React from 'react';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import '../../assets/css/style-2.css';
import shape12 from '../../assets/images/shape/shape-12.png';
import shape13 from '../../assets/images/shape/shape-13.png';
import shape14 from '../../assets/images/shape/shape-14.png';
import shape15 from '../../assets/images/shape/shape-15.png';

function ShowCaseHomeThree() {
    return (
        <>
            <section className="appie-showcase-area bg-retail-contacto" id="demo">
                <SimpleReactLightbox>
                    <SRLWrapper>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="appie-section-title text-center">
                                        <h3 className="appie-title">Contáctanos</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row appie-showcase-slider">
                                <div className="col-md-6 ml-auto">
                                    <form
                                        action="mailto:ventas@somossistemas.com"
                                        method="post"
                                        encType="text/plain"
                                    >
                                        <div className="form-group">
                                            <label className="text-light" htmlFor="subject">
                                                Asunto
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="subject"
                                                id="subject"
                                                value="Solicitud demo POS"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="text-light" htmlFor="subject">
                                                Descripción
                                            </label>
                                            <textarea
                                                className="form-control"
                                                name="body"
                                                id="body"
                                                cols="30"
                                                rows="10"
                                            ></textarea>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control main-btn bg-aftim-app mt-3"
                                                type="submit"
                                                value="Enviar"
                                            />
                                        </div>
                                    </form>
                                    <div className="text-center pt-5">
                                        <h4 className="text-light">
                                            ¡SOLICITA UNA DEMOSTRACIÓN GRATUITA!
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="showcase-shape-1">
                            <img src={shape14} alt="" />
                        </div>
                        <div className="showcase-shape-2">
                            <img src={shape13} alt="" />
                        </div>
                        <div className="showcase-shape-3">
                            <img src={shape12} alt="" />
                        </div>
                        <div className="showcase-shape-4">
                            <img src={shape15} alt="" />
                        </div>
                    </SRLWrapper>
                </SimpleReactLightbox>
            </section>
        </>
    );
}

export default ShowCaseHomeThree;

import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-aftim-dark.png';

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img className="w-50" src={logo} alt="" />
                                    </a>
                                </div>
                                <p>Colección de software administrativo y de ventas.</p>
                                <a href="#">
                                    Mas detalles <i className="fal fa-arrow-right" />
                                </a>
                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a href="https://es-la.facebook.com/somossistemasca">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://www.instagram.com/somossistemas/?hl=es">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li> */}
                                        <li>
                                            <a href="https://www.instagram.com/somossistemas/?hl=es">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://ve.linkedin.com/company/somos-sistemas-c.a.">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Compañia</h4>
                                <ul>
                                    <li>
                                        <Link to="/#">Acerca de</Link>
                                    </li>
                                    <li>
                                        <Link to="/#">Servicios</Link>
                                    </li>
                                    <li>
                                        <a href="/#">Cosos de Estudio</a>
                                    </li>
                                    <li>
                                        <Link to="/#">Blog</Link>
                                    </li>
                                    <li>
                                        <Link to="/#">Contacto</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Soporte</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">Community</Link>
                                    </li>
                                    <li>
                                        <a href="#">Resources</a>
                                    </li>
                                    <li>
                                        <a href="#">Faqs</a>
                                    </li>
                                    <li>
                                        <a href="#">Privacy Policy</a>
                                    </li>
                                    <li>
                                        <a href="#">Careers</a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Contáctanos</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:ventas@somossistemas.com">
                                            <i className="fal fa-envelope" />{' '}
                                            ventas@somossistemas.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+(58) 412 795 55 60">
                                            <i className="fal fa-phone" /> +(58) 412 795 55 60
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" /> Estacion de
                                            Servicio Cocheima, Arriba del antiguo Autolavado - Local
                                            1 - Piso 1, La Asunción 6311, Nueva Esparta
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="apps-download-btn">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fab fa-apple" /> Disponible para Mac &
                                                ios
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="#">
                                                <i className="fab fa-google-play" /> Disponible para
                                                Android
                                            </a>
                                        </li>
                                        <li>
                                            <a className="item-2" href="#">
                                                <i className="fab fa-microsoft" /> Disponible
                                                windows
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="copyright-text">
                                    <p>© 2022 Aftim.app. All rights reserved Somos Sistemas C.A.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;

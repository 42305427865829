import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/LOGO-PRINCIPAL-V2.png';
/* import StickyMenu from '../../lib/StickyMenu'; */
import Navigation from '../Navigation';

function HomeOneHeader({ action }) {
    useEffect(() => {
        /* StickyMenu(); */
    }, []);
    return (
        <header className="appie-header-area appie-sticky sticky">
            <div className="container">
                <div className="header-nav-box">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
                            <div className="appie-logo-box">
                                <Link className="my-n5" to="/">
                                    <img src={logo} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
                            <div className="appie-header-main-menu">
                                <Navigation />
                            </div>
                        </div>
                        <div className="col-lg-4  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
                            <div className="appie-btn-box text-right">
                                <a
                                    className="login-btn"
                                    href="https://dw.aftim.app"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fal fa-user" /> Iniciar Sesión
                                </a>
                                <a className="main-btn ml-30" href="/#demos">
                                    Demo
                                </a>
                                <div
                                    onClick={(e) => action(e)}
                                    className="toggle-btn ml-30 canvas_open d-lg-none d-block"
                                >
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HomeOneHeader;

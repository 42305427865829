import React from 'react';

function ProjectHomeOne() {
    return (
        <>
            <section className="appie-project-area pb-100" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-project-box wow animated slideInUp"
                                data-wow-duration="1000ms"
                                data-wow-delay="0ms"
                            >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="appie-project-content">
                                            <h3 className="title">
                                                Gestiona tu empresa con aftim.
                                            </h3>
                                            <p>Envíanos un E-mail con tu solicitud.</p>
                                            {/* <form
                                                action="mailto:ventas@somossistemas.com"
                                                method="post"
                                                encType="text/plain"
                                            > */}
                                            <div className="input-box mt-30">
                                                {/* <input
                                                        type="text"
                                                        placeholder="Tu requerimiento"
                                                        name="Asunto"
                                                    /> */}
                                                <a
                                                    className="btn-form-email"
                                                    href="mailto:ventas@somossistemas.com"
                                                >
                                                    Contactar
                                                </a>
                                            </div>
                                            {/* </form> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProjectHomeOne;

import React, { useEffect } from 'react';
import TopToBottom from '../lib/TopToBottom';

function BackToTop({ className }) {
    useEffect(() => {
        TopToBottom('.back-to-top');
    });
    return (
        <>
            <div className={`back-to-top ${className}`}>
                <div className="text-center my-3">
                    <a
                        className="fab fa-whatsapp bg-success d-float"
                        href="https://wa.me/+584127955560/?text=Contacto%20desde%20Aftim.app"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                    </a>
                </div>
                <a href="#">
                    <i className="fal fa-arrow-up" />
                </a>
            </div>
        </>
    );
}

export default BackToTop;

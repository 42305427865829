import React from 'react';
import pdf from '../../assets/files/AFTIM-FACTURACION-POS.pdf';

function TestimonialHomeOne() {
    return (
        <section className="appie-testimonial-area pt-100 pb-5" id="testimonial">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="text-light">
                                ¿Quieres saber más acerca del software POS Only?
                            </h3>
                            <a
                                href={pdf}
                                className="main-btn sbg-aftim-app mt-5"
                                target="_blank"
                                rel="noreferrer"
                            >
                                AFTIM EN PDF
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeOne;

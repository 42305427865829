import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import mgvela from '../../assets/images/clientes/mg-la-vela.jpg';
import applecicle from '../../assets/images/clientes/apple-cicle.jpg';
import dro4g from '../../assets/images/clientes/4g-dro.jpg';
import alivalencia from '../../assets/images/clientes/ali-valencia.jpg';

function TestimonialHomeOne() {
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <section className="appie-testimonial-area pt-100 pb-160" id="testimonial">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title text-light">Casos de éxito</h3>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="appie-testimonial-slider" style={{ position: 'relative' }}>
                            <span
                                className="prev slick-arrow"
                                style={{ display: 'block' }}
                                onClick={sliderPrev}
                                role="button"
                                tabIndex="0"
                            >
                                <i className="fal fa-arrow-left" />
                            </span>
                            <Slider ref={sliderRef} dots arrows={false}>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img
                                            className="img-fluid img-thumbnail w-50"
                                            src={mgvela}
                                            alt=""
                                        />
                                        <h5 className="title">Mundo Games LV</h5>
                                        <span>Enero 14, 2022</span>
                                    </div>
                                    <div className="text">
                                        {/* <p>
                                            Why I say old chap that is spiffing lurgy Oxford
                                            cracking goal bleeder, bog-standard Harry brilliant mush
                                            a blinding shot starkers.
                                        </p> */}
                                        {/* <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img
                                            className="img-fluid img-thumbnail w-50"
                                            src={applecicle}
                                            alt=""
                                        />
                                        <h5 className="title">Apple Cicle</h5>
                                        <span>Agosto 21, 2021</span>
                                    </div>
                                    <div className="text">
                                        {/* <p>
                                            Why I say old chap that is spiffing lurgy Oxford
                                            cracking goal bleeder, bog-standard Harry brilliant mush
                                            a blinding shot starkers.
                                        </p> */}
                                        {/* <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img
                                            className="img-fluid img-thumbnail w-50"
                                            src={dro4g}
                                            alt=""
                                        />
                                        <h5 className="title">Drogueria 4G</h5>
                                        <span>Mayo 7, 2021</span>
                                    </div>
                                    <div className="text">
                                        {/* <p>
                                            Why I say old chap that is spiffing lurgy Oxford
                                            cracking goal bleeder, bog-standard Harry brilliant mush
                                            a blinding shot starkers.
                                        </p> */}
                                        {/* <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                                <div className="appie-testimonial-item text-center">
                                    <div className="author-info">
                                        <img
                                            className="img-fluid img-thumbnail w-50"
                                            src={alivalencia}
                                            alt=""
                                        />
                                        <h5 className="title">Market Ali Valencia</h5>
                                        <span>Abril 14, 2022</span>
                                    </div>
                                    <div className="text">
                                        {/* <p>
                                            Why I say old chap that is spiffing lurgy Oxford
                                            cracking goal bleeder, bog-standard Harry brilliant mush
                                            a blinding shot starkers.
                                        </p> */}
                                        {/* <ul>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                            <li>
                                                <i className="fas fa-star" />
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                            </Slider>
                            <span
                                onClick={sliderNext}
                                role="button"
                                tabIndex="-1"
                                className="next slick-arrow"
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-right" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeOne;

import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    /* const scroll = (sectionData) => {
        if (sectionData !== '' && sectionData !== undefined) {
            const section = document.querySelector(sectionData);
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }; */
    return (
        <>
            <ul>
                <li>
                    <Link to="/#">Inicio</Link>
                    {/* <a href="/#">Inicio <i className="fal fa-angle-down" /></a> */}
                    {/* <ul className="sub-menu">
                        <li>
                            <Link to="/">Home 1</Link>
                        </li>
                        <li>
                            <Link to="/home-two">Home 2</Link>
                        </li>
                        <li>
                            <Link to="/home-three">Home 3</Link>
                        </li>
                        <li>
                            <Link to="/home-four">Home 4</Link>
                        </li>
                        <li>
                            <Link to="/home-five">Home 5</Link>
                        </li>
                        <li>
                            <Link to="/home-six">Home 6</Link>
                        </li>
                        <li>
                            <Link to="/home-seven">Home 7</Link>
                        </li>
                        <li>
                            <Link to="/home-eight">Home 8</Link>
                        </li>
                    </ul> */}
                </li>
                <li>
                    <Link to="/#">Servicios</Link>
                    {/* <a href="/#">Servicios</a> */}
                </li>
                <li>
                    <Link to="/#news-blog">Noticias</Link>
                    {/* <a href="/#news-blog">Noticias <i className="fal fa-angle-down" /></a> */}
                    {/* <ul className="sub-menu">
                        <li>
                            <Link to="/news">Paginas Nuevas</Link>
                        </li>
                        <li>
                            <Link to="/news/single-news">Pagina</Link>
                        </li>
                    </ul> */}
                </li>
                <li>
                    <Link to="/#faq-help">Ayuda</Link>
                    {/* <a href="#faq-help">Ayuda <i className="fal fa-angle-down" /></a> */}
                    {/* <ul className="sub-menu">
                        <li>
                            <Link to="/about-us">Ayuda</Link>
                        </li>
                        <li>
                            <Link to="/error">Error</Link>
                        </li>
                    </ul> */}
                </li>
                <li>
                    <Link to="/#contacto">Contacto</Link>
                    {/* <a href="/#contacto">Contacto</a> */}
                </li>
            </ul>
        </>
    );
}

export default Navigation;

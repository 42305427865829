import React from 'react';
/* import heroThumbTwo from '../../assets/images/hero-thumb-2.png'; */
/* import shapeTwo from '../../assets/images/shape/shape-2.png';
import shapeThree from '../../assets/images/shape/shape-3.png';
import shapeFour from '../../assets/images/shape/shape-4.png'; */

function HeroHomeOne() {
    return (
        <div className="appie-hero-area-models">
            <section className="appie-hero-area bg-market">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="appie-hero-content to-up">
                                <h1 className="appie-title text-aftim-app">MARKET</h1>
                                <p className="text-aftim-app">
                                    <b>La herramienta preferida para manejar tu mercado.</b>
                                </p>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="row d-none">
                                    <div className="col-6 d-xs-none">
                                        <ul className="text-left mt-5">
                                            <li className="mx-2 d-block">
                                                <p className="text-light">
                                                    <i className="fas fa-badge-check text-aftim-app"></i>{' '}
                                                    Tiendas de Moda
                                                </p>
                                            </li>
                                            <li className="mx-2 d-block">
                                                <p className="text-light">
                                                    <i className="fas fa-badge-check text-aftim-app" />{' '}
                                                    Complementos
                                                </p>
                                                {/* <a className="item-2" href="#">
                                                    
                                                </a> */}
                                            </li>
                                            <li className="mx-2 d-block">
                                                <p className="text-light">
                                                    <i className="fas fa-badge-check text-aftim-app" />{' '}
                                                    Deporte
                                                </p>
                                            </li>
                                            <li className="mx-2 d-block">
                                                <p className="text-light">
                                                    <i className="fas fa-badge-check text-aftim-app" />{' '}
                                                    Perfumería
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-6 d-xs-none">
                                        <ul className="text-left mt-5">
                                            <li className="mx-2 d-block">
                                                <p className="text-light">
                                                    <i className="fas fa-badge-check text-aftim-app" />{' '}
                                                    Juguetería
                                                </p>
                                            </li>
                                            <li className="mx-2 d-block">
                                                <p className="text-light">
                                                    <i className="fas fa-badge-check text-aftim-app" />{' '}
                                                    Quiosco
                                                </p>
                                            </li>
                                            <li className="mx-2 d-block">
                                                <p className="text-light">
                                                    <i className="fas fa-badge-check text-aftim-app" />{' '}
                                                    Librería
                                                </p>
                                            </li>
                                            <li className="mx-2 d-block">
                                                <p className="text-light">
                                                    <i className="fas fa-badge-check text-aftim-app" />{' '}
                                                    Comercio especializado
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-auto">
                            <div className="text-right">
                                <a href="#demo" className="main-btn bg-aftim-app mt-5">
                                    Solicita una Demo Gratuita
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/*                 <div className="hero-shape-1">
                    <img src={shapeTwo} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeThree} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeFour} alt="" />
                </div> */}
            </section>
        </div>
    );
}

export default HeroHomeOne;
